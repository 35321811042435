<template>
  <ul>
    <menu-options-item v-if="habilitacao.status !== 1" close label="Aprovar" @click="$emit('aprovar')" icon="check" label-class="text-positive" />
    <menu-options-item v-if="habilitacao.status !== 1" close label="Aprovar + Direito de Preferência" @click="$emit('aprovarDp')" icon="check" label-class="text-positive" />
    <menu-options-item v-if="habilitacao.status !== 99" close label="Bloquear" label-class="text-negative" @click="$emit('reprovar')" icon="times" />
  </ul>
</template>

<script>
import MenuOptionsItem from '../../../../layout/context-menu/context-window-options-item'
export default {
  name: 'lote-list-menu',
  props: ['habilitacao'],
  components: {MenuOptionsItem}
}
</script>
