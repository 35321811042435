<template>
  <div class="wrapper">
    <h5>
      Habilitações para o leilão
    </h5>
    <div class="m-b">
      Caso o leilão tenha obrigatoriedade de análise da aprovação, acesse o <a href="/#/arrematantes/habilitacao">Painel
        de Análise de Habilitações</a> para um acompanhamento das análises pendentes.
    </div>

    <div class="filters row font-11 justify-between">
      <div class="col text-grey-7" style="max-width: 170px; line-height: 20px">
        <!--{{table.serverPagination.rowsNumber}} lote{{table.serverPagination.rowsNumber > 1 ? 's' : ''}}-->
        {{ table.serverPagination.rowsNumber }} habilitado{{ Number(table.serverPagination.rowsNumber) > 1 ? 's' : '' }}
        <u-btn @click="load" icon="refresh" round rounded flat size="xs" :loading="table.loading"/>
      </div>
      <div class="col text-grey-7" style="max-width: 170px; line-height: 20px">
        <u-btn @click="exportar('excel')" flat icon="file-excel" icon-type="fa" icon-style="light" title="Exportar habilitados para Excel" label="Exportar habilitados" size="sm" no-caps />
      </div>
      <div class="col text-grey-7" style="max-width: 170px; line-height: 20px">
        <u-btn @click="exportar('excel', true)" flat icon="file-excel" icon-type="fa" icon-style="light" title="Exportar tudo" label="Exportar tudo" size="sm" no-caps />
      </div>
      <div class="col m-l-lg" style="line-height: 20px; max-width: 500px">
        <div class="row">
          <div class="col">
            <!--            <erp-checkbox class="flex no-wrap items-center m-r" v-model="table.filters.semCobranca">
                          <label class="no-select text-nowrap font-bold">
                            <span class="m-l-xs text-nowrap">Aprovados</span>
                          </label>
                        </erp-checkbox>

                        <erp-checkbox class="flex no-wrap items-center m-r" v-model="table.filters.comCobranca">
                          <label class="no-select text-nowrap font-bold">
                            <span class="m-l-xs text-nowrap">Reprovados</span>
                          </label>
                        </erp-checkbox>

                        <erp-checkbox class="flex no-wrap items-center m-r" v-model="table.filters.comCobranca">
                          <label class="no-select text-nowrap font-bold">
                            <span class="m-l-xs text-nowrap">Em análise</span>
                          </label>
                        </erp-checkbox>
                        -->

          </div>
        </div>
      </div>
      <div class="col" style="min-width: 300px;">
        <erp-s-field
            class="font-10 m-r-md"
            view="lr"
            label="Adicionar arrematante:"
        >
          <arrematante-input v-model="idArrematante" :finded-data-obj="cache.arrematanteSelected"
                             @updateSelected="(data) => {cache.arrematanteSelected = data; confirmaAdicionarHabilitado()}" />
        </erp-s-field>
      </div>

      <div class="col" style="">
        <erp-s-field
            class="font-10"
            view="lr"
            label="Buscar:"
        >
          <helper-input-btn>
            <erp-input simple-border v-model="table.filters.pesquisa"/>
            <a class="m-l-xs text-black">IR</a>
          </helper-input-btn>
        </erp-s-field>
      </div>
      <div class="col m-l m-b-n" style="max-width: 110px; text-align: right">
        <u-btn @click="listOptions" label="Configurar lista" no-caps size="sm" color="grey-4" class="text-grey-8"/>
      </div>
    </div>
    <div class="erp-list no-pad">
      <u-table
          ref="table"
          color="primary"
          :data="filteredServerData"
          :columns="table.columns"
          :visible-columns="visibledColumns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="erp-table"
          :rows-per-page-options="[100, 300, 500]"
      >
        <!--<template slot="top-right" slot-scope="props">
        </template>-->
        <u-tr slot="header" slot-scope="props">
          <u-th auto-width>
            <u-checkbox
                v-model="props.selected"
                :indeterminate="props.partialSelected"
                size="sm"
            />
          </u-th>
          <u-th v-for="col in props.cols" :key="col.name" :props="props">
            {{ col.label }}
          </u-th>
        </u-tr>

        <u-tr @dblclick.native="arrematanteWindow(props.row.arrematante.id)" slot="body" slot-scope="props"
              :props="props">
          <u-td auto-width>
            <u-checkbox color="primary" v-model="props.selected" size="xs"/>
          </u-td>
          <u-td key="apelido" :props="props">
            <div>{{ props.row.arrematante.apelido }}</div>
          </u-td>
          <u-td key="idArrematante" :props="props">
            <div>{{props.row.arrematante.id}}</div>
          </u-td>
          <u-td key="nome" :props="props">
            {{ props.row.arrematante.pessoa.name }}
          </u-td>
          <u-td key="analise" :props="props">
            <div class="text-negative" v-if="props.row.analise">
              <div v-for="a in props.row.analise.split(';')">{{a}}</div>
            </div>
          </u-td>
          <u-td key="data"
                :props="props">
            {{ props.row.dataHabilitacao.date|formatDate }}
          </u-td>
          <u-td key="status"
                :props="props">
            <span v-if="props.row.status === 1" class="text-positive">Aprovado</span>
            <span v-else-if="props.row.status === 0" class="text-purple">Em análise</span>
            <span v-else class="text-negative">Não aprovado</span>
          </u-td>
          <u-td class="text-center" key="options" :props="props">
            <a @click="downloadTermo(props.row)"><i class="fa fa-file-pdf font-14 text-blue-grey m-r-sm"></i><u-tooltip :offset="[5, 5]">Baixar Termo Digital</u-tooltip></a>
            <e-btn-table-options>
              <menu-options>
                <default-list-menu
                    @aprovar="aprovar(props.row)"
                    @reprovar="reprovar(props.row)"
                    @excluir="excluir(props.row)"
                    :habilitacao="props.row"/>
              </menu-options>
            </e-btn-table-options>
          </u-td>
          <!--          <context-menu-lote-item
                        @abrir="abrir(props.row)"
                        @abrirBem="abrirBem(props.row.bem.id)"
                        @removerLote="remover(props.row)"
                        :leilao="props.row" />-->
        </u-tr>
      </u-table>

      <!--          v-if="table.selected.length > 0"-->
    </div>

    <lista-habilitacoes-lotes class="m-t" />
  </div>
</template>

<script>
import {UCheckbox, UTable, UTd, UTh, UTr, LocalStorage, UTooltip} from 'uloc-vue'
import {EBtnTableOptions, /*ErpCheckbox, */ErpSField, ErpInput, HelperInputBtn} from 'uloc-vue-plugin-erp'
import {list, aprovar, reprovar, habilitar, excluir, downloadTermoHabilitacao} from '@/domain/arrematante/services/arrematante-habilitacao'
import MenuOptions from '../../../layout/context-menu/context-window-options'
import DefaultListMenu from './context/list-menu'
//import ContextMenuLoteItem from '../context/list-context-lote'
import arrematanteWindow from '../../../arrematante/windows/arrematante'
import AlteracaoStatusLoteMixin from '../include/AlteracaoStatusLoteMixin'
import listOptions from '../../windows/listOptions'
import DefaultMixin from '@/components/leiloes/mixin'
import ArrematanteInput from '../../../arrematante/helpers/input/ArrematanteInput'
import {donwloadFile} from "@/utils/downloadFile"
import ListaHabilitacoesLotes from "components/leiloes/components/habilitacoes/Lotes"
import tableColumnsCache from "@/utils/tableColumnsCache"

const listVersion = 1
export const listName = 'leilao.habilitacoes.list'
export const listStorage = tableColumnsCache(listName, [
  {label: 'Apelido', name: 'apelido', active: true, sortable: true, ordering: 1},
  {label: 'Arrematante', name: 'nome', active: true, sortable: true, ordering: 1},
  {label: 'Análise Cadastral', name: 'analise', active: true, sortable: true, ordering: 1},
  {label: 'Data de habilitação', name: 'data', active: true, sortable: true, ordering: 1},
  {label: 'Situação', name: 'status', active: true, sortable: true, ordering: 1}
], listVersion)

let filters = {
  semCobranca: true,
  comCobranca: true,
  pesquisa: null
}

export default {
  name: 'ListaHabilitacoes',
  mixins: [AlteracaoStatusLoteMixin, DefaultMixin],
  inject: ['page', 'erplayout'],
  props: {},
  data() {
    return {
      leilao: this.page.leilao,
      listStorage: listStorage,
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 0, // specifying this determines pagination is server-side
          rowsPerPage: 100
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            required: o.required || null,
            align: 'left'
          }
        }).concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ]),
        filter: '',
        filters: JSON.parse(JSON.stringify(filters)),
        selected: [],
        loading: false
      },
      idArrematante: null,
      cache: {
        arrematanteSelected: null,
      }
    }
  },
  components: {
    ListaHabilitacoesLotes,
    ArrematanteInput,
    // ContextMenuLoteItem,
    MenuOptions,
    UTable,
    UTh,
    UTr,
    UTd,
    UCheckbox,
    EBtnTableOptions,
    DefaultListMenu,
    // ErpCheckbox,
    ErpSField,
    ErpInput,
    HelperInputBtn,
    UTooltip
  },
  mounted() {
    this.page.setActive('hbl')
    console.log(this.leilao)
    this.load()
  },
  computed: {
    visibledColumns() {
      return this.listStorage.filter(item => {
        /*if (item.name === 'imagem') {
          return !this.table.filters.ocultarFotos
        }*/
        return item.active
      }).map(item => item.name)
    },
    filteredServerData() {
      let data = this.table.serverData.slice()

      let busca = this.table.filters.pesquisa
      if (busca && String(busca).length > 0) {
        console.log(busca)
        data = data.filter(lt => {
          return String(lt.arrematante.apelido).toLowerCase().indexOf(busca) > -1
              || String(lt.arrematante.pessoa.name).toLowerCase().indexOf(busca) > -1
        })
      }
      return data
      /*return this.table.serverData.slice().sort(function (a, b) {
        a = Number(a['numero'])
        b = Number(b['numero'])
        return (a === b ? 0 : a > b ? 1 : -1) * 1 // -1 = desc, 1 = asc
      })*/
    }
  },
  methods: {
    load() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    exportar (tipo, tudo = false) {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      }, true, tudo)
    },
    request({pagination, filter}, exportar = false, tudo = false) {
      this.table.loading = true
      this.table.selected = []
      let _filter = this.filtros
      let filtros = encodeURI(`&sortBy=${pagination.sortBy}&descending=${pagination.descending}&search=${filter}&leilao=${this.leilao.id}`)
      if (exportar) {
        filtros += '&export=true'
      }
      if (exportar) {
        filtros += '&all=true'
      }

      list(pagination.rowsPerPage, pagination.page, filtros, exportar)
          .then((response) => {
            if (exportar) {
              donwloadFile(response)
              this.table.loading = false
              return
            }
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = response.data.total
            this.table.serverData = response.data.result || []

            this.table.loading = false
            this.loadedData()
          })
          .catch(error => {
            this.loading = false
          })
    },
    listOptions: listOptions,
    loadedData() {
      this.$emit('loaded', this.table.serverData)
    },
    arrematanteWindow: arrematanteWindow,
    aprovar(habilitacao) {
      aprovar(habilitacao.id)
          .then(({data}) => {
            // this.load()
            this.$uloc.notify({color: 'positive', message: habilitacao.arrematante.apelido + ' habilitado com sucesso'})
            habilitacao.status = 1
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    reprovar(habilitacao) {
      reprovar(habilitacao.id)
          .then(({data}) => {
            // this.load()
            this.$uloc.notify({
              color: 'negative',
              message: habilitacao.arrematante.apelido + ' teve a habilitação reprovada.'
            })
            habilitacao.status = 99
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    adicionarHabilitado() {
      habilitar(this.idArrematante, this.leilao.id)
          .then(({data}) => {
            this.load()
            this.$uloc.notify({color: 'positive', message: 'Arrematante habilitado com sucesso!'})
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    confirmaAdicionarHabilitado () {
      this.$uloc.dialog({
        title: 'Atenção',
        message: `Tem certeza que deseja adicionar o arrematante ${this.cache.arrematanteSelected.pessoa.name}?`,
        cancel: true,
        color: 'secondary'
      }).then(data => {
        this.$uloc.notify({color: 'warning', message: 'Habilitando arrematante, aguarde'})
        this.adicionarHabilitado()
      }).catch(() => {
      })
    },
    downloadTermo (habilitacao) {
      this.$uloc.loading.show({message: 'Baixando arquivo...'})
      downloadTermoHabilitacao(this.leilao.id, habilitacao.id)
          .then((response) => {
            this.$uloc.loading.hide()
            donwloadFile(response)
          })
          .catch(error => {
            this.$uloc.loading.hide()
            this.alertApiError(error)
          })
    },
    excluir (habilitacao) {
      this.$uloc.dialog({
        title: 'Atenção',
        message: `Tem certeza que deseja excluir esta habilitação?`,
        cancel: true,
        color: 'secondary'
      }).then(data => {
        excluir(habilitacao.id)
            .then(({data}) => {
              // this.load()
              this.$uloc.notify({
                color: 'negative',
                message: habilitacao.arrematante.apelido + ' teve a habilitação excluída.'
              })
              this.load()
            })
            .catch(error => {
              this.alertApiError(error)
            })
      }).catch(() => {
      })
    },
  }
}
</script>

<style lang="stylus">
.status-envio {
  .nao-enviado {
    background-color rgb(239, 239, 239)
  }
}
</style>
