<template>
  <ul>
    <menu-options-item v-if="habilitacao.status !== 1" close label="Aprovar" @click="$emit('aprovar')" icon="check" label-class="text-positive" />
    <menu-options-item v-if="habilitacao.status !== 99" close label="Reprovar" label-class="text-negative" @click="$emit('reprovar')" icon="times" />
    <menu-options-item close label="Excluir Habiltação" label-class="text-negative" @click="$emit('excluir')" />
  </ul>
</template>

<script>
import MenuOptionsItem from '../../../../layout/context-menu/context-window-options-item'
export default {
  name: 'lote-list-menu',
  props: ['habilitacao'],
  components: {MenuOptionsItem}
}
</script>
